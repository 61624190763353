<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-col cols="12">
			<v-card class="mt-3">
				<v-row class="px-3 px-lg-0 mb-n6">
					<v-col class="d-none d-md-block pl-lg-5 pr-1">
						<v-autocomplete
							:items="secoes.lista"
							item-text="secao"
							item-value="idsecao"
							label="Seção"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idsecao"
							@change="listarGrupos(), listarSubGrupos()"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="grupos.lista"
							item-text="grupo"
							item-value="idgrupo"
							label="Grupo"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idgrupo"
							@change="listarSubGrupos()"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="subgrupos.lista"
							item-text="subgrupo"
							item-value="idsubgrupo"
							label="SubGrupo"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idsubgrupo"
						></v-autocomplete>
					</v-col>
					<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
						<v-sheet class="text-center" height="100%">
							<v-row class="pa-3">
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="secoes.lista"
										item-text="secao"
										item-value="idsecao"
										label="Seção"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idsecao"
										@change="listarGrupos(), listarSubGrupos()"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="grupos.lista"
										item-text="grupo"
										item-value="idgrupo"
										label="Grupo"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idgrupo"
										@change="listarSubGrupos()"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="subgrupos.lista"
										item-text="subgrupo"
										item-value="idsubgrupo"
										label="SubGrupo"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idsubgrupo"
									></v-autocomplete>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-bottom-sheet>
					<v-col class="px-1 pr-lg-5 d-flex">
						<v-text-field
							class="d-flex d-md-none"
							:disabled="carregando"
							v-model="busca.busca"
							label="Buscar"
							outlined
							dense
							@keydown.enter="listar(0)"
							append-outer-icon="mdi-menu"
							@click:append-outer="sheet = !sheet"
						/>
						<v-text-field
							class="d-none d-md-block"
							:disabled="carregando"
							v-model="busca.busca"
							label="Buscar"
							outlined
							dense
							@keydown.enter="listar(0)"
						/>
						<v-btn
							:disabled="carregando"
							class="ml-2 mt-1"
							color="primary"
							elevation="0"
							fab
							x-small
							@click="listar(0)"
						>
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th class="text-left">ID</th>
								<th class="text-left">Produto</th>
								<th class="text-left">Seção</th>
								<th class="text-left">Grupo</th>
								<th class="text-left">SubGrupo</th>
								<th class="text-left">Custo</th>
								<th class="text-center">Margem</th>
								<th class="text-left">Valor venda</th>
								<th class="text-left"></th>
							</tr>
						</thead>
						<tbody>
							<template v-if="dados.total > 0">
								<tr v-for="(u, i) in dados.lista" :key="i">
									<td>
										<v-btn fab elevation="0" x-small color="primary" @click="listarProduto(u)">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</td>
									<td class="text-truncate">{{u.idproduto}}</td>
									<td class="text-truncate">{{u.descricao}}</td>
									<td class="text-truncate">{{u.secao}}</td>
									<td class="text-truncate">{{u.grupo}}</td>
									<td class="text-truncate">{{u.subgrupo}}</td>
									<td class="text-truncate">{{u.custo | formataDinheiro}}</td>
									<td class="text-truncate text-center">{{u.margem.toFixed(2)}} %</td>
									<td class="text-truncate">{{u.venda | formataDinheiro}}</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="10">Nenhum registro encontrado.</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
					<Paginacao
						:total="dados.total"
						@atualizar="listar"
						:carregando="carregando"
						:pg="pgSelecionada"
					/>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
		<v-dialog v-model="dialog" persistent scrollable>
			<v-card v-if="produtos.length > 0">
				<v-card-title class="pa-4">
					<span>{{produtos[0].idproduto}} - {{produtos[0].descricao}}</span>
					<v-spacer />
					<v-text-field
						style="max-width:250px;"
						outlined
						class="mb-n6"
						label="Margem de Lucro Geral"
						suffix="%"
						dense
						type="number"
						v-model.number="margemGeral"
						@change="valorVendaGeral()"
					></v-text-field>
				</v-card-title>
				<v-divider />
				<v-card-text class="pa-3">
					<v-simple-table dense style="border: 1px solid #c6c6c6">
						<thead class="primary">
							<tr>
								<th class="text-truncate text-left white--text">Filial</th>
								<th class="text-truncate text-left white--text">Custo última compra</th>
								<th class="text-truncate text-center white--text">Margem atual</th>
								<th class="text-truncate text-left white--text">Valor de venda atual</th>
								<th class="text-truncate text-center white--text">Nova margem</th>
								<th class="text-truncate text-left white--text">Novo valor de venda</th>
								<th class="text-truncate text-left white--text">Alterado</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(p, i) in produtos" :key="i">
								<td class="text-truncate">{{p.idempresa}} - {{p.empresa}}</td>
								<td class="text-truncate text-center">R$ {{p.custoultimacompra.toFixed(2)}}</td>
								<td class="text-truncate text-center">{{p.margem.toFixed(2)}} %</td>
								<td class="text-truncate text-center">R$ {{p.venda.toFixed(2)}}</td>
								<td class="text-truncate text-center" style="max-width: 50px;">
									<v-text-field
										class="mb-n3 text-body-2"
										reverse
										dense
										type="number"
										prefix="%"
										v-model.number="p.margemnova"
									></v-text-field>
								</td>
								<td class="text-truncate text-center">R$ {{valorVenda(p).toFixed(2)}}</td>
								<td>
									<v-checkbox readonly dense class="ml-3 mb-n3" :input-value="verificaAlteracao(p)" value></v-checkbox>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn :loading="carregando" @click="dialog = false" color="error" elevation="0">
						<v-icon class="pr-1">mdi-cancel</v-icon>Cancelar
					</v-btn>
					<v-btn :loading="carregando" color="primary" elevation="0" @click="alterarValores()">
						<v-icon class="pr-1">mdi-content-save-outline</v-icon>Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import Paginacao from "../Widgets/Paginacao.vue";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	components: { Paginacao, CardDialog },
	name: "ComprasManutencaoPreco",
	mixins: [mixinFilial],
	data: () => ({
		sheet: false,
		carregando: false,
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dados: { lista: [], total: 0 },
		secoes: {},
		grupos: {},
		subgrupos: {},
		busca: {},
		produtos: [],
		baseProdutos: [],
		margemGeral: null,
		produtosAlterados: [],
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "usuario", "pgLimit"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listarSecoes() {
			this.busca.idsecao = null;
			this.secoes = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/secao/listar`, {
					idfabricante: null,
				})
				.then((res) => {
					this.secoes = res.data;
					this.secoes.lista = this.secoes.lista.map((v) => {
						return {
							idsecao: v.idsecao,
							secao: `${v.idsecao} - ${v.secao}`,
						};
					});
				});
		},
		listarGrupos() {
			this.busca.idgrupo = null;
			this.grupos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/grupo/listar`, {
					idfabricante: null,
					idsecao: this.busca.idsecao || null,
				})
				.then((res) => {
					this.grupos = res.data;
					this.grupos.lista = this.grupos.lista.map((v) => {
						return {
							idgrupo: v.idgrupo,
							grupo: `${v.idgrupo} - ${v.grupo}`,
						};
					});
				});
		},
		listarSubGrupos() {
			this.busca.idsubgrupo = null;
			this.subgrupos = { lista: [] };
			return axios
				.post(`${this.backendUrl}produto/subgrupo/listar`, {
					idfabricante: null,
					idsecao: this.busca.idsecao || null,
					idgrupo: this.busca.idgrupo || null,
				})
				.then((res) => {
					this.subgrupos = res.data;
					this.subgrupos.lista = this.subgrupos.lista.map((v) => {
						return {
							idsubgrupo: v.idsubgrupo,
							subgrupo: `${v.idsubgrupo} - ${v.subgrupo}`,
						};
					});
				});
		},
		listar(pg) {
			this.pgSelecionada = pg;
			localStorage.setItem("filtros_preco", JSON.stringify(this.busca));
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/produtocusto/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					idsecao: this.busca.idsecao || null,
					idgrupo: this.busca.idgrupo || null,
					idsubgrupo: this.busca.idsubgrupo || null,
					busca: this.busca.busca || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		listarProduto(produto) {
			this.margemGeral = null;
			return axios
				.post(`${this.backendUrl}compra/produtocusto/listar/filial`, {
					idsubproduto: produto.idproduto,
				})
				.then((res) => {
					this.dialog = true;
					this.produtos = res.data.lista
						.filter((v) => v.custoultimacompra > 0)
						.map((v) => {
							return {
								...v,
								margemnova: v.margem,
							};
						});
					this.baseProdutos = res.data.lista
						.filter((v) => v.custoultimacompra > 0)
						.map((v) => {
							return {
								...v,
								margemnova: v.margem,
							};
						});
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Erro ao listar produto(s)!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		valorVenda(produto) {
			let valor;
			if (produto.pis == 1.65 && produto.cofins == 7.6) {
				valor =
					produto.custoultimacompra /
					(1 -
						produto.icmssaida / 100 -
						produto.pis / 100 -
						produto.cofins / 100 -
						produto.margemnova / 100);
			} else {
				let reducao =
					produto.icmssaida / 100 -
					(produto.icmssaida / 100) *
						(produto.reducaotributaria / 100);
				valor =
					produto.custoultimacompra /
					(1 -
						reducao -
						produto.pis / 100 -
						produto.cofins / 100 -
						produto.margemnova / 100);
			}
			return valor;
		},
		valorVendaGeral() {
			if (this.margemGeral) {
				this.produtos.forEach((p) => (p.margemnova = this.margemGeral));
			}
		},
		verificaAlteracao(produto) {
			let margemBase = this.baseProdutos.filter(
				(p) => p.idempresa == produto.idempresa
			)[0].margem;
			if (margemBase == produto.margemnova) {
				return false;
			} else {
				return true;
			}
		},
		alterarValores() {
			this.produtosAlterados = [];
			this.produtos.forEach((p) => {
				this.baseProdutos.forEach((pa) => {
					if (
						p.idempresa == pa.idempresa &&
						p.margemnova != pa.margem
					) {
						let produto = {};
						produto.idempresa = p.idempresa;
						produto.idsubproduto = p.idproduto;
						produto.margem = p.margemnova;
						this.produtosAlterados.push(produto);
					}
				});
			});
			if (this.produtosAlterados.length == 0) {
				this.dialogErro.title = "Aviso";
				this.dialogErro.msg = "Nenhuma margem foi alterada!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.carregando = true;
			return axios
				.post(
					`${this.backendUrl}compra/produtocusto/atualizar`,
					this.produtosAlterados
				)
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.dialog = false;
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg =
							"Produto(s) alterado(s) com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listar(0);
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg = "Erro ao atualizar produto(s)!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Erro ao atualizar produto(s)!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			await this.listarSecoes();
			await this.listarGrupos();
			await this.listarSubGrupos();
			const json = localStorage.getItem("filtros_preco");
			const filtro = JSON.parse(json);
			if (filtro) {
				this.busca = filtro;
				this.listar();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>